#buttonArrowDown::after {
  margin-left: 3px;
  color: inherit;
  content: "\2228";
}

#buttonArrowUp::after {
  margin-left: 3px;
  color: inherit;
  content: "\2227";
}

#copyright {
  font-family: adobe-clean, sans-serif !important;
  font-style: normal;
}

#separator {
  font-size: 12px;
  opacity: 0.7;
}

#buttonLabel {
  font-weight: 400;
  font-size: 12px;
}
.footerFont {
  font-weight: 300;
  font-size: 12px;
}
